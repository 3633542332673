import React from 'react'
import Layout from '../../components/Layout/Layout'

const HighSchool = () => {
  return (
    <Layout location='academicOffer'>

    </Layout>
  )
}

export default HighSchool